import React, { Component } from 'react';
import { Col, Row, Spinner, Badge } from 'reactstrap';

export class Trending extends Component {
    static displayName = Trending.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: props.id,
            data: props.data,
            description: props.data.overview,
            posterLink: props.data.posterSmall,
            rating: props.data.rating
        }

        this.controller = new window.AbortController();
        this.signal = this.controller.signal;
    }

    async componentDidMount() {
        await this.setState({ data: this.props.data, loading: false });
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    render() {
        let t = this.state.data;
        let rating = t.rating ? t.rating.toFixed(1) : null;
        let rtImage = t.rating > 6.0
            ? 'tomatometer-fresh.svg'
            : t.rating === 0
                ? 'tomatometer-empty.svg'
                : 'tomatometer-rotten.svg';
        return this.state.loading
            ?
            <Row>
                <Col className="text-center my-auto">
                    <Spinner type="grow"> </Spinner>
                </Col>
            </Row>
            :
            <div className="cursor-pointer position-relative" id={this.state.id} data-torrent={t.id} data-item={JSON.stringify(t)} data-category={t.mediaType} onClick={this.props.onClick}>
                <Badge color="plex" className="trending-rank d-flex flex-row gap-1 align-items-center">
                    <img src={`images/${rtImage}`} alt="" width="10" height="10" /> 
                    {rating}
                </Badge>
                {t.posterPath ? 
                    <><img src={t.posterSmall} alt={t.displayName} className="rounded d-none d-sm-inline-block" />
                    <img src={t.posterXS} alt={t.displayName} className="rounded d-sm-none" /></>
                    :
                    <span>{t.title}</span>
                }
                
            </div>
    }
}